import { LatLng } from "leaflet";

export class UserLocation {


    coordinates: LatLng | undefined;
    accuracy: number | undefined;

    constructor(coordinates?: LatLng, accuracy?: number) {
        this.coordinates = coordinates;
        this.accuracy = accuracy;
    }

    static EMPTY = new UserLocation();
}


import { useMap } from "react-leaflet";

class MapControllerProps {
	onMapReady?: (map: any) => void;
}

export default function MapController (props: MapControllerProps) {
	const map = useMap();
	if (props.onMapReady) {
		props.onMapReady(map);
	}

	return <></>;
};

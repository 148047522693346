
export class Color {
	
    readonly opacity = 0.6;

    readonly CarolinaBlue = `rgba(81, 160, 213, ${this.opacity})`;
    readonly NeonGreen = `rgba(57, 255, 20, 1)`;
    readonly ScanbotRed = `rgba(200, 25, 60, ${this.opacity})`;
    readonly HotPink = `rgba(255, 105, 180, ${this.opacity})`;
    readonly Fuscia = `rgba(255, 0, 255, ${this.opacity})`;
    readonly GoogleMapsBlue = `rgba(31, 142, 232, 0.6)`;

    readonly LocationMarker = `rgb(83, 131, 236)`;
    readonly White = "white";

    polyline(): string | undefined {
        return this.CarolinaBlue
		// return "rgba(31, 120, 180, 0.5)";
	}

    highlightedPolyline(): string | undefined {
        return this.NeonGreen
        // return "rgba(51, 160, 44, 0.9)";
        // return "rgba(106, 61, 154, 0.8)";
        // return "rgba(227, 26, 28, 0.8)";
    }


    readonly DistrictPolygon = [
        "#FF8A66", // Soft Red-Orange
        "#66FF8A", // Soft Lime Green
        "#668AFF", // Soft Royal Blue
        "#FF66C2", // Soft Pink
        "#66FFF2", // Soft Cyan
        "#FFD966", // Soft Yellow
        "#B266FF", // Soft Purple
        "#FFA566"  // Soft Orange
    ];
}

export default class Style {
    
    static readonly color = new Color();

    public static readonly FONT = "14px/20px 'Calibri', Arial, Helvetica, sans-serif";
    public static readonly SHADOW = "0px 5px 10px 0px rgba(0, 0, 0, 0.5)"
}

import React from "react";
import MathUtils from "../service/math-utils";
import Style from "../service/style";
import Activity from "../model/activity";

export default class Legend extends React.Component<any, any> {

    override render(): React.ReactNode {

        let total = 0;
        let totalKmTallinn = 0;
        let totalCurrentYear = 0;

        this.props.runs.forEach((activity: Activity) => {
            total += activity.distance;
            if (activity.isInTallinn()) {
                totalKmTallinn += activity.distance;
            }
            if (activity.isIn2024()) {
                totalCurrentYear += activity.distance;
            }
        });

        total = MathUtils.toKm(total);
        totalKmTallinn = MathUtils.toKm(totalKmTallinn);
        totalCurrentYear = MathUtils.toKm(totalCurrentYear);

        const entries = [
            { key: "Grand total", value: total + "km" },
            { key: "Total in Tallinn", value: totalKmTallinn + "km" },
            { key: "Total in 2024", value: totalCurrentYear + "km" }
        ]

        return (
            <div style={{
                width: 220,
                backgroundColor: "white",
                boxShadow: Style.SHADOW,
                borderRadius: 3,
                font: Style.FONT,
                padding: 10
            }}>
                {entries.map((entry: any) => {
                    return (<div key={entry.key} style={{ display: "flex", paddingBottom: 3 }}>
                        <span style={{ flex: 5, fontWeight: 600 }}>{entry.key.toUpperCase()}</span>
                        <span style={{ flex: 3 }}>{entry.value}</span>
                    </div>);
                })}
            </div>
        )
    }
}
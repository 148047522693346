import Activity from "../model/activity";
import District from "../model/district";

export default class Networking {

	public static readonly instance = new Networking();

	async fetchActivities(): Promise<Activity[]> {

		const url = this.isGea() ? `data-gea.json` : `data.json`
		const json = await this.fetch(url);

		const activities: Activity[] = [];
		for (let i = 0; i < json.length; i++) {
			activities.push(Activity.fromJson(json[i]));
		}

		return activities;
	}

	async fetchDistricts(): Promise<District[]> {
		const districts = await this.fetch(`districts.json`);
		return districts.map((district: any) => {
			const result = new District();
			result.name = district.district;
			result.city = district.city;
			result.polygon = district.polygon;
			return result;
		});
	}

	async fetch(url: string): Promise<any> {
		const response = await fetch(url);
		return await response.json();
	}

	isGea(): boolean {
		return window.location.href.indexOf("gea") !== -1;
	}
}
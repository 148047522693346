import React from 'react';
import { MapContainer, Polygon, Polyline, TileLayer, Tooltip } from 'react-leaflet';
import polyline from '@mapbox/polyline'
import Networking from './service/networking';
import Activity, { ActivityType } from './model/activity';
import Style from './service/style';
import ActivityPopup from './views/activity-popup';
import Legend from './views/legend';
import MapEventHandler from './utils/map-event-handler';
import RunDetailsPopup from './views/run-details-popup';
import MathUtils from './service/math-utils';
import UrlParsing from './utils/url-parsing';
import MapUtils from './utils/map-utils';
import FilterFAB from './views/filter-fab';
import FilterOverlay from './views/filter-overlay';
import District from './model/district';
import { DistrictPolygon } from './service/district-parser';
import LocationData from './service/location-data';
import MapController from './utils/map-controller';
import LocationCircle from './views/location-circle';
import { UserLocation } from './model/user-location';

class State {
    activities: Activity[] = [];
    zones: DistrictPolygon[] = [];
    highlightedActivity: Activity | null = null;
    center: any;
    zoom?: number;
    nodeId: number | undefined;
    showFilters: boolean = false;
}

class App extends React.Component<any, State> {

    map: any;

    private locationCircle: LocationCircle | null = null;

    constructor(props: any) {
        super(props);

        UrlParsing.process(window.location.href);

        const city = UrlParsing.city;
        let center = LocationData.CityCoordinates.tallinn;
        Object.keys(LocationData.CityCoordinates).forEach((key: string) => {
            if (city === key) {
                center = LocationData.CityCoordinates[key];
            }
        });

        if (UrlParsing.latLng) {
            center = UrlParsing.latLng;
        }

        this.state = {
            activities: [],
            zones: [],
            highlightedActivity: null,
            center: center,
            zoom: UrlParsing.zoom ?? 12,
            nodeId: UrlParsing.nodeId,
            showFilters: false,
        }
    }

    override async componentDidMount(): Promise<void> {

        const activities = await Networking.instance.fetchActivities();
        const polylines: Activity[] = [];

        let runs = activities.filter((activity: Activity) => activity.type === ActivityType.Run);

        if (Networking.instance.isGea()) {
            runs = activities;
        }

        let totalBeforeFirstMarathon = 0;
        runs.forEach((activity: Activity) => {
            if (activity.isBefore11September2022()) {
                totalBeforeFirstMarathon += activity.distance ?? 0;
            }
        });

        console.log("Total runs:", runs.length);
        console.log("Total KM before first marathon:", MathUtils.toKm(totalBeforeFirstMarathon));

        let totalSinceDecember20th = 0;
        runs.forEach((activity: Activity) => {
            if (activity.isAfter20December2023()) {
                totalSinceDecember20th += activity.distance ?? 0;
            }
        });

        console.log("Total KM since last pair of shoes:", MathUtils.toKm(totalSinceDecember20th));

        let colorIndex = 0;

        runs.forEach((activity: Activity) => {
            activity.decodePolyline(polyline);
            activity.setStyle({ color: Style.color.polyline(), weight: 4 });

            if (UrlParsing.showDistricts) {
                activity.style.weight = 2;
                if (activity.name?.includes("Linnaosad")) {
                    activity.style.color = Style.color.DistrictPolygon[colorIndex];
                    colorIndex++;
                }
            }

            if (this.state.nodeId === activity.id) {
                this.highlightNode(activity);

                if (!MapUtils.isLineInView(this.map, activity)) {
                    // TODO: if latLng is set, should we still focus on the line?
                    MapUtils.animateZoomToLine(this.map, activity);
                }
            }
            polylines.push(activity);
        });

        this.setState({ activities: polylines });

        // const polygons = await DistrictParser.process(runs);
        // this.setState({ zones: polygons });
    }

    onMapReady(map: any) {
        console.log("Map ready", map);
        this.map = map;

        this.map.locate().on("locationfound", (e: any) => {
            console.log("Location found", e);
            this.locationCircle?.update(new UserLocation(e.latlng, e.accuracy));
        });
    }

    orderDistrictsByDistance(districts: District[]): District[] {
        return districts.sort((a: District, b: District) => b.totalDistanceRun - a.totalDistanceRun);
    }

    highlightNode(current: Activity | null) {
        this.state.activities.forEach((activity: Activity) => {
            activity.style.color = Style.color.polyline();
            activity.style.weight = 4;
        });
        if (current != null) {
            current.style.color = Style.color.highlightedPolyline();
            current.style.weight = 7;
            UrlParsing.updateNodeIdParam(current.id);
        }

        this.reload();
        this.setState({ highlightedActivity: current });
    }

    override render(): React.ReactNode {

        // const DEFAULT = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        // const DARK = "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
        const GRAY = "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";

        const url = GRAY;

        const attribution = "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        const margin = 10;

        return (
            <div>
                <div style={{ position: "fixed", top: margin, right: margin, zIndex: 401 }}>
                    <Legend runs={this.state.activities} />
                    <RunDetailsPopup activity={this.state.highlightedActivity} />
                </div>

                <MapContainer center={this.state.center} zoom={this.state.zoom} style={{ height: "100vh" }}>
                    <MapEventHandler
                        onClick={(event: any) => { this.highlightNode(null); }}
                        onMove={(event: any) => {
                            const map = event.target
                            // this.highlightNode(null);
                            UrlParsing.updateLocationParam(map.getCenter(), map.getZoom());
                        }}
                    />
                    <TileLayer attribution={attribution} url={url} />
                    <MapController onMapReady={(map: any) => { this.onMapReady(map) }} />

                    {this.state.activities.map((activity: Activity, i: number) => {

                        if (UrlParsing.showDistricts) {
                            if (activity.name?.includes("Linnaosad")) {
                                const options = { color: activity.style.color, weight: activity.style.weight };
                                return (
                                    <Polygon key={i} positions={[activity.positions]} pathOptions={options}>
                                        <Tooltip content={MathUtils.toKmString(activity.distance)} direction="center"
                                            offset={[0, 0]} opacity={1}
                                            permanent />
                                    </Polygon>
                                );
                            }
                            return null;
                        }

                        return <Polyline
                            key={i}
                            positions={activity.positions}
                            pathOptions={{ color: activity.style.color, weight: activity.style.weight }}
                            eventHandlers={{
                                click: (e) => {
                                    this.highlightNode(activity);
                                    e.target.bringToFront();
                                }
                            }}>
                            <ActivityPopup activity={activity} />
                        </Polyline>
                    })}
                    {this.state.zones.map((node: any, i: number) => (
                        <Polygon
                            key={i}
                            positions={node.positions}
                            pathOptions={{ color: node.color, weight: node.weight }}
                            eventHandlers={{
                                click: (e) => {
                                    console.log("Clicked on zone", node.name);
                                }
                            }}
                        />
                    ))}
                    <LocationCircle ref={ref => this.locationCircle = ref } />

                </MapContainer>
                <FilterFAB onClick={() => {
                    this.setState({ showFilters: true });
                }} />
                <FilterOverlay
                    visible={this.state.showFilters}
                    onClose={() => this.setState({ showFilters: false })}
                />
            </div>
        )
            ;
    }

    reload() {
        this.setState({ activities: this.state.activities });
    }
}

export default App;

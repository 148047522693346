
import React from "react";
import { Popup } from "react-leaflet";
import Activity from "../model/activity";
import MathUtils from "../service/math-utils";

export default class ActivityPopup extends React.Component<any, any> {

    override render(): React.ReactNode {

        const activity = this.props.activity as Activity;

        const entries = [
            { key: "Duration", value: MathUtils.toHourMinuteString(activity.duration.moving_time) },
            { key: "Distance", value: MathUtils.toKmString(activity.distance) },
            { key: "Date", value: activity.date?.toLocaleString() }
        ];

        return (
            //@ts-ignore
            <Popup style={{display: "none"}}>

                
            </Popup >
        )
    }
}
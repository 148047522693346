import { LatLng } from "leaflet";

export default class UrlParsing {

	private static params: any = {};

	static process(url: string): any {

		let result: any = {};
		const query = url.split('?')[1];
		if (query === undefined) {
			return result;
		}

		result = this.decodeParams(query);
		this.params = result;
		return result;
	}

	private static decodeParams(search: string): any {
		if (search.startsWith('?')) { 
			search = search.substring(1);
		}
		const pairs = search.split('&');
		const result: any = {};
		pairs.forEach(pair => {
			const keyValue = pair.split('=');
			result[keyValue[0]] = keyValue[1];
		});
		return result;
	}

	private static encodeParams(params: any): string {

		let result = "";
		Object.keys(params).forEach(key => {
			if (key !== undefined && params[key] !== undefined) {
				result += key + '=' + params[key] + '&';
			}
		});
		return result.substring(0, result.length - 1);
	}

	static get city(): string | undefined {
		return this.params.city;
	}

	static get location(): string | undefined {
		return this.params.location;
	}

	static get nodeId(): number | undefined {
		return parseInt(this.params.nodeId);
	}

	static DISTRICT_PARAM = "districts";
	static get showDistricts(): boolean {
		return this.params[this.DISTRICT_PARAM] === 'true';
	}

	static get latLng(): [number, number] | undefined {
		const split = this.params.location?.split(',');

		if (split === undefined || split.length !== 3) {
			return undefined;
		}
		return [parseFloat(split[0]), parseFloat(split[1])];
	}

	static get zoom(): number | undefined {
		const split = this.params.location?.split(',');
		if (split === undefined || split.length !== 3) {
			return undefined;
		}
		return parseFloat(split[2]);
	}

	static updateNodeIdParam(id: any) {
		this.updateParam('nodeId', id);
	}

	static updateLocationParam(center: LatLng, zoom: number) {
		const location = center.lat.toFixed(4) + ',' + center.lng.toFixed(4) + ',' + zoom;
		this.updateParam('location', location);
	}

	private static updateParam(key: string, value: string) {
		const base = window.location;
		const params = this.decodeParams(base.search);
		params[key] = value;
		var refresh = base.protocol + "//" + base.host + base.pathname + `?${this.encodeParams(params)}`;
		window.history.pushState({ path: refresh }, '', refresh);
	}

}
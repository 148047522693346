
import React from "react";
import { Circle, CircleMarker } from "react-leaflet";
import { UserLocation } from "../model/user-location";
import { LatLngExpression } from "leaflet";
import Style from "../service/style";

export class State {
    location?: UserLocation;
}

export default class LocationCircle extends React.Component<{}, State> {

    constructor(props: any) {
        super(props);
        this.state = new State();
    }

    public update(location: UserLocation) {
        this.setState({ location });
    }

    override render(): JSX.Element {

        if (!this.state.location) {
            return <></>;
        }

        if (!this.state.location.coordinates) {
            return <></>;
        }

        const center: LatLngExpression = [this.state.location.coordinates.lat, this.state.location.coordinates.lng];

        return <div>
            <Circle
                center={center}
                radius={this.state.location.accuracy || 0}
                pathOptions={{ color: Style.color.LocationMarker, weight: 1, opacity: 0.5 }}
            />
            <CircleMarker
                center={center}
                radius={10}
                
                // stroke={false}
                pathOptions={{ color: Style.color.White, fillColor: Style.color.LocationMarker, weight: 1, opacity: 255 }}
                />
        </div>
    }
}
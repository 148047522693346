
import React from "react";
import Activity from "../model/activity";
import MathUtils from "../service/math-utils";
import Style from "../service/style";

export default class RunDetailsPopup extends React.Component<any, any> {

    override render(): React.ReactNode {

        const activity = this.props.activity as Activity;

        if (activity === null) {
            return null;
        }

        const dateFormat: any = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const entries = [
            { key: "Duration", value: MathUtils.toHourMinuteString(activity.duration.moving_time) },
            { key: "Distance", value: MathUtils.toKmString(activity.distance) },
            { key: "Date", value: activity.date?.toLocaleTimeString([], dateFormat) }
        ];

        return (
            <div style={{
                width: 220,
                backgroundColor: "white",
                boxShadow: Style.SHADOW,
                zIndex: 401,
                borderRadius: 3,
                padding: 10,
                marginTop: 10,
                font: Style.FONT
            }}>
                <div style={{ fontWeight: 600, fontSize: 15, marginBottom: 10 }}>{activity.name}</div>
                {entries.map((entry: any) => {
                    return (<div key={entry.key} style={{ display: "flex", paddingBottom: 5 }}>
                        <div style={{ width: "50%", fontWeight: 600 }}>{entry.key}</div>
                        <div>{entry.value}</div>
                    </div>);
                })}
            </div>
        )
    }
}
import { useMapEvents } from "react-leaflet";

const MapEventHandler = (props: any) => {  
    useMapEvents({
        click: event => props.onClick(event),
        moveend: event => props.onMove(event)
    });
    return null;
}

export default MapEventHandler;
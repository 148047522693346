
import L from "leaflet";
import Point from "../model/point";

export default class MapUtils {

    static boundsFromPoints(points: [number, number][]) {
        const bounds = points.reduce((bounds, point) => {
            return bounds.extend(point);
        }, new L.LatLngBounds(points[0], points[0]));
        return bounds;
    }

    static isLineInView(map: any, line: any): boolean {
        const bounds = map.getBounds();
        const lineBounds = this.boundsFromPoints(line.positions);
        return bounds.contains(lineBounds);
    }

    static animateZoomToLine(map: any, line: any) {
        const bounds = this.boundsFromPoints(line.positions);
        map.fitBounds(bounds, {
            padding: [20, 20]
        });
    }

    // Function to decode a polyline encoded string
    static decodePolyline(encoded: string): Point[] {
        const len = encoded.length;
        let index = 0;
        const array: Point[] = [];
        let lat = 0;
        let lng = 0;

        while (index < len) {
            let b;
            let shift = 0;
            let result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lat += dlat;

            shift = 0;
            result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            const point = {
                lat: lat / 1e5,
                lng: lng / 1e5
            };
            array.push(point);
        }
        return array;
    }

    // Function to check if a point is inside a polygon
    static isPointInsidePolygon(point: Point, polygon: Point[]): boolean {
        let crossings = 0;
        const polygonLength = polygon.length;
        for (let i = 0; i < polygonLength; i++) {
            const vertex1 = polygon[i];
            const vertex2 = polygon[(i + 1) % polygonLength];
            if ((vertex1.lng <= point.lng && point.lng < vertex2.lng) || (vertex2.lng <= point.lng && point.lng < vertex1.lng)) {
                const edgeSlope = (vertex2.lat - vertex1.lat) / (vertex2.lng - vertex1.lng);
                const edgeIntercept = vertex1.lat - edgeSlope * vertex1.lng;
                const pointOnEdge = edgeSlope * point.lng + edgeIntercept;
                if (pointOnEdge <= point.lat) {
                    crossings++;
                }
            }
        }
        return crossings % 2 === 1;
    }

    // Function to calculate the percentage of a polyline inside a polygon
    static percentageOfPolylineInsidePolygon(encodedPolyline: string, polygon: Point[]): number {
        const decodedPolyline = this.decodePolyline(encodedPolyline);
        let pointsInsidePolygon = 0;
        const totalPoints = decodedPolyline.length;

        for (let i = 0; i < totalPoints; i++) {
            if (this.isPointInsidePolygon(decodedPolyline[i], polygon)) {
                pointsInsidePolygon++;
            }
        }

        return (pointsInsidePolygon / totalPoints) * 100;
    }
}

export default class MathUtils {

    static round(total: number, arg1: number): number {
        return Math.round(total * arg1) / arg1;
    }

    static toHoursAndMinutes(totalSeconds: number) {
        const totalMinutes = Math.floor(totalSeconds / 60);
      
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return { h: hours, m: minutes, s: seconds };
    }  
     
    static toHourMinuteString(seconds: number | undefined) {

        if (seconds === undefined) {
            return "";
        }

        const dict = this.toHoursAndMinutes(seconds);
        let result = "";

        if (dict.h < 10 && dict.h > 0) {
            result += "0";
        }

        if (dict.h > 0) {
            result += dict.h + "h ";
        }
        

        if (dict.m < 10) {
            result += "0";
        }

        result += dict.m + "m";

        return result;
    }

    static toKm(meters: number | undefined): number {
        if (meters === undefined) {
            return 0;
        }

        return MathUtils.round(meters / 1000, 2);
    }

    static toKmString(meters: number | undefined) {

        if (meters === undefined) {
            return "";
        }

        const km = (meters / 1000).toFixed(2);
        return km + "km";
    }

    static calculate_area(points: Array<{ lat: number, lng: number }>) {
        let total = 0;
        for (let i = 0, l = points.length; i < l; i++) {
            const addX = points[i].lat;
            const addY = points[i === points.length - 1 ? 0 : i + 1].lng;
            const subX = points[i === points.length - 1 ? 0 : i + 1].lat;
            const subY = points[i].lng;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }
        return Math.abs(total);
    }

    static calculate_bounds(points: Array<{ lat: number, lng: number }>) {
        let bounds = { minLat: 0, maxLat: 0, minLng: 0, maxLng: 0 };

        for (let i = 0; i < points.length; i++) {
            if (points[i].lat < bounds.minLat) {
                bounds.minLat = points[i].lat;
            }
            if (points[i].lat > bounds.maxLat) {
                bounds.maxLat = points[i].lat;
            }
            if (points[i].lng < bounds.minLng) {
                bounds.minLng = points[i].lng;
            }
            if (points[i].lng > bounds.maxLng) {
                bounds.maxLng = points[i].lng;
            }
        }

        return bounds;
    }

    static calculate_center(bounds: { minLat: number, maxLat: number, minLng: number, maxLng: number }) {
        return {
            lat: (bounds.minLat + bounds.maxLat) / 2,
            lng: (bounds.minLng + bounds.maxLng) / 2
        };
    }

    static calculate_zoom(area: number) {
        const zoomLevel = 18 - Math.log(area) / Math.log(2);
        return zoomLevel;
    }

    static calculate_zoom_from_bounds(bounds: { minLat: number, maxLat: number, minLng: number, maxLng: number }) {
        const area = this.calculate_area([
            { lat: bounds.minLat, lng: bounds.minLng },
            { lat: bounds.minLat, lng: bounds.maxLng },
            { lat: bounds.maxLat, lng: bounds.maxLng },
            { lat: bounds.maxLat, lng: bounds.minLng }
        ]);

        return this.calculate_zoom(area);
    }

    static calculate_zoom_from_points(points: Array<{ lat: number, lng: number }>) {
        const bounds = this.calculate_bounds(points);
        return this.calculate_zoom_from_bounds(bounds);
    }

    static calculate_zoom_from_activity(activity: any) {
        const points = activity.map.summary_polyline.points;
        return this.calculate_zoom_from_points(points);
    }

    static calculate_center_from_activity(activity: any) {
        const bounds = this.calculate_bounds(activity.map.summary_polyline.points);
        return this.calculate_center(bounds);
    }

    static calculate_center_from_points(points: Array<{ lat: number, lng: number }>) {
        const bounds = this.calculate_bounds(points);
        return this.calculate_center(bounds);
    }

    static calculate_center_from_bounds(bounds: { minLat: number, maxLat: number, minLng: number, maxLng: number }) {
        return this.calculate_center(bounds);
    }

}
import React from "react";
import Style from "../service/style";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class NTDatePicker extends React.Component<any, any> {
    render() {
        return (
            <div style={{ display: "flex" }}>
                <div style={{paddingRight: 10, width: 70, font: Style.FONT}}>{this.props.title}</div>
                <DatePicker
                    selected={this.props.selected}
                    onChange={this.props.onChange}
                    dateFormat={"dd/MMM/yyyy"}
                />
            </div>
        );
    }
}

export default class FilterOverlay extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
        }
    }

    override render(): React.ReactNode {

        if (!this.props.visible) {
            return <></>;
        }

        return (
            <div style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                top: 0,
                zIndex: 6000,
            }}
                onClick={this.props.onClose}
            >
                <div style={{
                    backgroundColor: "white",
                    position: "absolute",
                    bottom: 50,
                    right: 10,
                    width: 360,
                    height: 160,
                    borderRadius: 5,
                    boxShadow: Style.SHADOW,
                    display: "flex",
                    flexDirection: "column",
                    padding: 10
                }}
                    onClick={(e) => {
                        e.stopPropagation();
                        // Prevent click from propagating to parent
                    }}>
                    <NTDatePicker title={"Start Date"} selected={this.state.startDate} onChange={(date: Date) => {
                        this.setState({ startDate: date });

                    }} />
                    <div style={{height: 10}}></div>
                    <NTDatePicker title={"End Date"} selected={this.state.endDate} onChange={(date: Date) => {
                        this.setState({ endDate: date });
                    }} />
                </div>
            </div>
        )
    }
}
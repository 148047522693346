
export default class LocationData {

    static readonly CityCoordinates: any = {
        tallinn: [59.4370, 24.7536],
        bonn: [50.7374, 7.0982],
        parnu: [58.3859, 24.4971],
        tartu: [58.3780, 26.7295],
    };

}
import React from "react";
import Style from "../service/style";

export default class FilterFAB extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            isMouseDown: false
        }
    }

    override render(): React.ReactNode {
        return (
            <div style={{
                position: "fixed",
                bottom: 30,
                right: 10,
                zIndex: 401,
                width: 32,
                height: 32,
                backgroundColor: "white",
                borderRadius: "50%",
                padding: 7,
                boxShadow: Style.SHADOW,
                opacity: this.state.isMouseDown ? 0.5 : 1
            }}
                onMouseDown={() => this.setState({ isMouseDown: true })}
                onMouseUp={() => this.setState({ isMouseDown: false })}
                onClick={this.props.onClick}
            >
                <img style={{ objectFit: "contain", height: "inherit", width: "inherit" }} src="/assets/icons/filters.png" alt="filters" />
            </div>
        )
    }
}